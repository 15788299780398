.form-parent{
  display: flex;
}

.stepper{
  display: flex;
  height: 90vh;
  margin-top: 15vh;
  margin-bottom: 15vh;
  padding-left: 2vw;
  padding-right: 5vw;
  justify-content: center;
  align-items: center;
}

.center{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-container{
  padding: 50px;
  border : solid 1px rgb(235, 233, 233);
  border-radius: 10px;
  width: 100%;
  margin: 10vh 20px;
}

h3{
  margin: 0px;
}

.flex-column{
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.flex-row{
  display: flex;
  column-gap: 20px;
}

.step-label{
  font-size: 16px;
}

.preview-link{
  padding-top: 10px;
  padding-right: 5px;
  color: rgb(54, 53, 53);
}

.width-drop{
  width: 300px;
}

.wh-drop{
  width: 200px;
  height: 200px;
}

input[type="file"] {
  display: none;
}

.image-adder{
  margin-top: 75px;
  width: 50px;
  height: 50px;
  line-height: 40px;
  border-radius: 50%;
  font-size: 50px;
  color: #fff;
  text-align: center;
  background: #4C78F3;
}

.socials{
  margin-left: 50px;
}

.edit-icon{
  padding-top: 10px;
}

.cross-icon{
  padding-top: 6px;
}

.upload-btn{
  border: solid 1px rgb(235, 233, 233);
  padding: 5px 25px;
  border-radius: 10px;
}

.upload-status{
  background-color: #4C78F3;
  color: #fff;
}

.margin-b{
  margin-bottom: 20px;
}

.test-map-item{
  padding: 10px;
  border : solid 1px rgb(235, 233, 233);
  border-radius: 10px;
}

.test-map-item div{
  padding-top: 3px;
} 

.margin-top{
  margin-top: 25px;
}

@media only screen and (max-width: 600px) {
  .flex-row{
    flex-direction: column;
    row-gap: 20px;
  }
  .stepper{
    display: none;
  }

  .flex-row button{
    max-width: fit-content;
  }
}