body {
  margin: 0;
  font-family: "Whitney";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: "Whitney";
  src: url(https://firebasestorage.googleapis.com/v0/b/the-notary-app.appspot.com/o/Whitney-Book.otf?alt=media&token=7bf5ae91-695d-4daf-9bae-81730b9b4988) format("opentype");
}